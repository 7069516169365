import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UserUpdateProperties from '../../types/UserUpdateProperties'

const YESTERDAY_HEADING = 'Yesterday'
const TODAY_HEADING = 'Today'
const ISSUES_HEADING = 'Issues'

const UserUpdate = (props: UserUpdateProperties) => {
  const summaryHeading = `${props.fullName} (${props.userName})'s updates`

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      p={1}
      m={1}
      ml={10}
      mr={10}
    >
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            component="span"
            display="block"
            p={1}
            mb={-1}
            borderRadius="5%"
            fontWeight="fontWeightBold"
          >
            {summaryHeading}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            <Box
              component="span"
              display="block"
              p={1}
              m={1}
              bgcolor="primary.main"
              color="white"
              width="20%"
              borderRadius="5%"
            >
              {YESTERDAY_HEADING}
            </Box>
            <Box
              component="span"
              display="block"
              p={1}
              mt={-1}
              mb={2}
              bgcolor="background.paper"
              textAlign="left"
              style={{wordWrap: 'break-word'}}
            >
              {props.yesterdayComment}
            </Box>
            <Box
              component="span"
              display="block"
              p={1}
              m={1}
              bgcolor="primary.main"
              color="white"
              width="20%"
              borderRadius="5%"
            >
              {TODAY_HEADING}
            </Box>
            <Box
              component="span"
              display="block"
              p={1}
              mt={-1}
              mb={2}
              bgcolor="background.paper"
              textAlign="left"
              style={{wordWrap: 'break-word'}}
            >
              {props.todayComment}
            </Box>
            <Box
              component="span"
              display="block"
              p={1}
              m={1}
              bgcolor="primary.main"
              color="white"
              width="20%"
              borderRadius="5%"
            >
              {ISSUES_HEADING}
            </Box>
            <Box
              component="span"
              display="block"
              p={1}
              mt={-1}
              mb={2}
              bgcolor="background.paper"
              textAlign="left"
              style={{wordWrap: 'break-word'}}
            >
              {props.issuesComment}
            </Box>
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default UserUpdate

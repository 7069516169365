import React, { useEffect } from 'react'
import { Skeleton } from 'antd'
import { LicenseDetailsContext, LicenseDetailsProvider, PageView, } from 'trello-shared-resources/dist'
import DailyUpdates from '../components/daily-updates/DailyUpdates'
import LicensedDailyUpdatesProperties from '../types/LicensedDailyUpdatesProperties'

const LicensedDailyUpdates = (licensedDailyUpdatesProperties: LicensedDailyUpdatesProperties) => {
    useEffect(() => {
        PageView('DailyUpdates')
    }, [])

    return (
        <LicenseDetailsProvider>
            <LicenseDetailsContext.Consumer>
                {(licenseDetails: any) => {
                    if (licenseDetails.isLoading) {
                        return <Skeleton active={true}/>
                    }
                    return <DailyUpdates licenseDetails={licenseDetails} selectedMenuItem={licensedDailyUpdatesProperties.selectedMenuItem}/>
                }}
            </LicenseDetailsContext.Consumer>
        </LicenseDetailsProvider>
    )
}

export default LicensedDailyUpdates

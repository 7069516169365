class TrelloDetails {
  private static licenseDetails: any

  static createInstance = (licenseDetails: any) => {
    if (licenseDetails !== undefined) {
      TrelloDetails.licenseDetails = licenseDetails
    }
  }

  static getApiKey = () => {
    return TrelloDetails.licenseDetails.apiKey
  }

  static getJwt = async () => {
      return await TrelloDetails.licenseDetails.trelloIframeContext.jwt()
  }

  static getContext = () => {
    return TrelloDetails.licenseDetails.trelloIframeContext
  }

  static getAppKey = () => {
    return TrelloDetails.getContext().options.appKey
  }

  static getAppName = () => {
    return TrelloDetails.getContext().options.appName
  }

  static getUserName = () => {
    return TrelloDetails.getContext().args[0].context.username
  }

  static getMemberId = () => {
    return TrelloDetails.getContext().args[0].context.member
  }

  static getBoardId = () => {
    return TrelloDetails.getContext().args[0].context.board
  }

  static getUserFullName = async () => {
    const getFullName = await TrelloDetails.getContext().member('all')
    const fullName = await getFullName.fullName
    return fullName
  }

  static getIsAuthorized = () => {
    return TrelloDetails.licenseDetails.isAuthorized
  }

  static getIsLicensed = () => {
    return TrelloDetails.licenseDetails.isLicensed()
  }

  static getLicenseDetails = () => {
    return TrelloDetails.licenseDetails
  }
}

export default TrelloDetails

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 500,
      },
      '& .MuiInputBase-root':{
        alignItems: 'baseline',
      }
    },
    input: {
        minHeight: 100,
      },
  })
)

export { useStyles }

import { Moment } from 'moment'

/**
 * Utility class for retriving, manipulating dates and date formats
 */
class DailyUpdatesDate {
  static readonly PERSISTANCE_DATE_FORMAT = 'YYYY-MM-DD'
  static readonly DISPLAY_DATE_FORMAT = 'dddd DD MMMM YYYY'
  static readonly MIN_DATE = '01-01-2018'

 /**
  * Gets the latest date users can select (usually in a date picker component)
  * 
  * @startingDate The starting date from which calculate the future date (e.g. moment() for today's date)
  * @numberOfDays The number of days to add the starting date
  */
  static getFutureDate = (startingDate: Moment, numberOfDays: number): string => {
    return startingDate.add(numberOfDays, 'days').format(DailyUpdatesDate.DISPLAY_DATE_FORMAT)
  }
  
}

export default DailyUpdatesDate

import React, {useContext, useRef} from 'react'
import {ClickAwayListener, MenuItem, MenuList, Paper} from '@material-ui/core'
import FileType from '../../types/FileType'
import DownloadMenuProperties from '../../types/DownloadMenuProperties'
import {LicenseDetailsContext, TrackActionEvent} from 'trello-shared-resources/dist'

/**
 * Renders a dropdown menu with a list of all formats supported.
 * To support a format, please add a new MenuItem to the MenuList.
 */
const DownloadMenu = (downloadMenuProperties: DownloadMenuProperties) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const licenseDetailsContext = useContext(LicenseDetailsContext)

  const onDownload = (
    event: React.MouseEvent<EventTarget>,
    fileType: FileType
  ) => {
    downloadMenuProperties.onDownload(fileType)
    onClose(event)
    const context = licenseDetailsContext.trelloIframeContext.getContext()
    TrackActionEvent('Button Click', context, {board_id: context.board, button: 'Download File'})
   }

  const onClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    downloadMenuProperties.onSetOpen(false)
  }

  const onListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      downloadMenuProperties.onSetOpen(false)
    }
  }

  return (
    <Paper>
      <ClickAwayListener onClickAway={onClose}>
        <MenuList
          autoFocusItem={downloadMenuProperties.open}
          id="daily-updates-menu-list"
          onKeyDown={onListKeyDown}
        >
          <MenuItem
            id="daily-updates-download-excel-item"
            onClick={(event) => onDownload(event, FileType.EXCEL)}
          >
            Download Excel
          </MenuItem>
        </MenuList>
      </ClickAwayListener>
    </Paper>
  )
}

export default DownloadMenu

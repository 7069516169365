import React, {useEffect, useRef, useState} from 'react'
import {IconButton, Popper, Tooltip} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined'
import DownloadMenu from './DownloadMenu'
import {useStyles} from './downloadStyles'
import DownloadProperties from '../../types/DownloadProperties'
import FileType from '../../types/FileType'
import moment from 'moment'
import DailyUpdatesDate from '../../utils/DailyUpdatesDate'
import Persistence from '../../modules/persistence/Peristence'
import {UpdateProperties} from '../../modules/persistence/PersistenceProperties'
import TrelloDetails from '../../utils/TrelloDetails'
import {downloadFile} from '../../utils/FileDownload'

const Download = (props: DownloadProperties) => {
    const classes = useStyles()

    const [isHovering, setIsHovering] = useState(false)
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const onDownload = async (fileType: FileType) => {
        try {
            const updateData = await Persistence.getUpdates({
                date: props.date,
                board_id: TrelloDetails.getBoardId(),
                member_id: TrelloDetails.getMemberId()
            })
            // @ts-ignore
            updateData.forEach((arr: UpdateProperties) => {
                // @ts-ignore
                delete arr.board_id
                // @ts-ignore
                delete arr.member_id
                // @ts-ignore
                delete arr.id
            })

            const filename =
                'daily update ' +
                moment(props.date).format(DailyUpdatesDate.PERSISTANCE_DATE_FORMAT)
            downloadFile(filename, fileType, updateData)
        } catch (ex) {
            console.error('Download Error: ', ex)
        }
    }

    const onToggle = () => setOpen((prevOpen) => !prevOpen)


    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <div className={classes.root}>
            <Tooltip title="Download Your Daily Updates" placement="top">
                <IconButton
                    id="daily-updates-download-excel-icon"
                    aria-controls={open ? 'daily-updates-menu-list' : undefined}
                    aria-haspopup="true"
                    ref={anchorRef}
                    onClick={onToggle}
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}
                >
                    {isHovering ? (
                        <CloudDownloadIcon fontSize="large"/>
                    ) : (
                        <CloudDownloadOutlinedIcon fontSize="large"/>
                    )}
                </IconButton>
            </Tooltip>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
            >
                <DownloadMenu
                    open={open}
                    onDownload={(fileType) => onDownload(fileType)}
                    onSetOpen={setOpen}
                />
            </Popper>
        </div>
    )
}

export default Download

import FileType from '../types/FileType'
import {getExcelFile} from 'trello-shared-resources/dist'
import SaveFile from 'trello-shared-resources/dist/modules/file/SaveFile'

export const downloadFile = (filename: string, fileType: FileType, fileData: any) => {
    switch (fileType) {
        case FileType.EXCEL:
            const xlsxOutput = getExcelFile(fileData)
            const saveFile = new SaveFile('application/octet-stream')
            const saveFilename = filename + '.xlsx'
            saveFile.saveDataToFile(saveFilename, xlsxOutput)
            break
        default:
            throw new Error('Unsupported file type')
    }
}
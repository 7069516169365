import React, { useState, useEffect } from 'react'
import Date from '../date/Date'
import Comments from '../comments/Comments'
import SaveUpdate from '../save-update/SaveUpdate'
import PersonalUpdateProperties from '../../types/PersonalUpdateProperties'
import Persistence from '../../modules/persistence/Peristence'
import TrelloDetails from '../../utils/TrelloDetails'

const PersonalUpdate = (props: PersonalUpdateProperties) => {
    const [yesterdayComment, setYesterdayComment] = useState('')
    const [todayComment, setTodayComment] = useState('')
    const [issuesComment, setIssuesComment] = useState('')

    const [memberId] = useState(TrelloDetails.getMemberId())
    const [boardId] = useState(TrelloDetails.getBoardId())

    const onChangeYesterdayComment = (comment: string) => {
        setYesterdayComment(comment)
    }
    const onChangeTodayComment = (comment: string) => {
        setTodayComment(comment)
    }
    const onChangeIssuesComment = (comment: string) => {
        setIssuesComment(comment)
    }

    const onSaveUpdate: any = () => {
        if (process.env.REACT_APP_READONLY_MODE !== 'true') {
            return Persistence.saveUpdate({
            board_id: boardId,
            member_id: memberId,
            yesterday: yesterdayComment,
            today: todayComment,
            issues: issuesComment,
            date: props.date
        })
            .then(response => {
                if(response.ok) {
                    props.onUpdated()
                    return 'Update saved 🎉'
                } else return 'Update could not be saved!'
            })
            .catch(() => 'Update could not be saved!')
        } else {
            return Promise.resolve('Apologies, we are on maintenance. Try to reload the page and try again.')
        }
    }

    useEffect(() => {
        setYesterdayComment('')
        setTodayComment('')
        setIssuesComment('')

        Persistence.getUpdate({
            board_id: boardId,
            member_id: memberId,
            date: props.date
        })
            .then((response) => {
                if (response.hasOwnProperty('date')) {
                    setYesterdayComment(response.yesterday)
                    setTodayComment(response.today)
                    setIssuesComment(response.issues)
                }
            }).catch(() => {
                console.error('Error getting update')
        })
    }, [props.date, boardId, memberId])

    return (
        <div>
            <Date date={props.date} onChangeDate={props.onChangeDate}/>
            <Comments
                yesterdayComment={yesterdayComment}
                todayComment={todayComment}
                issuesComment={issuesComment}
                onChangeYesterdayComment={onChangeYesterdayComment}
                onChangeTodayComment={onChangeTodayComment}
                onChangeIssuesComment={onChangeIssuesComment}
            />
            <SaveUpdate
                yesterdayComment={yesterdayComment}
                todayComment={todayComment}
                issuesComment={issuesComment}
                onSaveUpdate={onSaveUpdate}
            />
        </div>
    )
}

export default PersonalUpdate

import React from 'react'
import TextField from '@material-ui/core/TextField'
import {useStyles} from './commentsStyles'
import CommentsProperties from '../../types/CommentsProperties'

const Comments = (props: CommentsProperties) => {
  const classes = useStyles()

  const onChangeYesterdayComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const comment = event.target.value
    props.onChangeYesterdayComment(comment)
  }

  const onChangeTodayComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const comment = event.target.value
    props.onChangeTodayComment(comment)
  }

  const onChangeIssuesComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const comment = event.target.value
    props.onChangeIssuesComment(comment)
  }

  return (
    <div id="daily-updates-comments" className={classes.root}>
      <TextField
        label="What I did yesterday"
        id="daily-updates-comments-yesterday"
        value={props.yesterdayComment}
        variant="filled"
        multiline={true}
        maxRows={3}
        fullWidth={true}
        InputProps={{
          className: classes.input,
        }}
        onChange={onChangeYesterdayComment}
      />

      <TextField
        label="What I'm doing today"
        id="daily-updates-comments-today"
        value={props.todayComment}
        variant="filled"
        multiline={true}
        maxRows={3}
        fullWidth={true}
        InputProps={{
          className: classes.input,
        }}
        onChange={onChangeTodayComment}
      />

      <TextField
        label="Issues I'm facing"
        id="daily-updates-comments-issues"
        value={props.issuesComment}
        variant="filled"
        multiline={true}
        maxRows={3}
        fullWidth={true}
        InputProps={{
          className: classes.input,
        }}
        onChange={onChangeIssuesComment}
      />
    </div>
  )
}

export default Comments
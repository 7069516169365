import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import DateProperties from '../../types/DateProperties'
import { useStyles } from './dateStyles'
import DailyUpdatesDate from '../../utils/DailyUpdatesDate'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import TrelloDetails from '../../utils/TrelloDetails'

const DateComponent = (props: DateProperties) => {
  const classes = useStyles()
  
  const date = props.date

  const [todayDate] = useState(moment(date).format(DailyUpdatesDate.DISPLAY_DATE_FORMAT))
  const [yesterdayDate] = useState(moment(date).add(-1, 'days').format(DailyUpdatesDate.DISPLAY_DATE_FORMAT))
  const [tomorrowDate] = useState(moment(date).add(1, 'days').format(DailyUpdatesDate.DISPLAY_DATE_FORMAT))
  const [selectedDate, setSelectedDate] = useState(todayDate.toString())
  const trelloContext = TrelloDetails.getContext().getContext()

  const onChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value
    setSelectedDate(date)

    const displayDateFormat = moment(date, DailyUpdatesDate.DISPLAY_DATE_FORMAT).toString()
    const persistenceDateFormat = moment(new Date(displayDateFormat)).format(DailyUpdatesDate.PERSISTANCE_DATE_FORMAT)
    props.onChangeDate(persistenceDateFormat)
    TrackActionEvent('Select Daily Update Date', trelloContext, {board_id: trelloContext.board})
  }

  return (
    <TextField
      className={classes.root}
      id="daily-updates-select-date-menu"
      select
      label="Your daily update for"
      size="medium"
      InputLabelProps={{
        className: classes.inputLabel,
      }}
      value={selectedDate}
      onChange={onChangeDate}
      SelectProps={{
        native: true,
      }}
      variant="outlined"
    >
      <option id="daily-updates-option-date-yesterday" value={yesterdayDate}>
        {yesterdayDate}
      </option>
      <option id="daily-updates-option-date-today" value={todayDate}>
        {todayDate}
      </option>
      <option id="daily-updates-option-date-tomorrow" value={tomorrowDate}>
        {tomorrowDate}
      </option>
    </TextField>
  )
}

export default DateComponent

import React, { useState } from 'react'
import moment from 'moment'
import PersonalUpdate from '../personal-update/PersonalUpdate'
import DailyUpdatesDate from '../../utils/DailyUpdatesDate'
import DailyUpdatesProperties from '../../types/DailyUpdatesProperties'
import TeamUpdates from '../team-updates/TeamUpdates'
import TrelloDetails from '../../utils/TrelloDetails'
import { renderTrelloContextMissing } from 'trello-shared-resources/dist'
import { Grid } from '@material-ui/core'
import { DailyUpdatesMenuEnum } from '../../views/DailyUpdatesMenuEnum'
import LeftBar from 'trello-shared-resources/dist/components/leftBar/LeftBar'
import { useStyles } from './dailyUpdatesStyles'
import { ButtonItem } from '@atlaskit/side-navigation'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import { getMenuContent } from 'trello-shared-resources/dist/services/MenuService'

const DailyUpdates = (dailyUpdatesProperties: DailyUpdatesProperties) => {
    const classes = useStyles()
    const [selectedMenuItem, setSelectedMenuItem] = useState(dailyUpdatesProperties.selectedMenuItem || DailyUpdatesMenuEnum.DAILY_UPDATES)

    TrelloDetails.createInstance(dailyUpdatesProperties.licenseDetails)

    const [date, setDate] = useState(moment().format(DailyUpdatesDate.PERSISTANCE_DATE_FORMAT).toString())
    const [isUpdated, setIsUpdated] = useState(false)

    const onChangeDate = (date: string) => {
        setDate(date)
    }

    const onUpdated = () => {
        setIsUpdated(!isUpdated)
    }

    if (!TrelloDetails.getContext()) {
        return renderTrelloContextMissing()
    }

    let mainDialogContent = getMenuContent(TrelloDetails.getLicenseDetails(), selectedMenuItem)
    if (!mainDialogContent) {
        if ((!TrelloDetails.getIsAuthorized() || !TrelloDetails.getIsLicensed())) {
            TrelloDetails.getLicenseDetails().trelloIframeContext.modal({
                url: './index.html?apptype=error',
                fullscreen: true,
                resizable: false,
                title: 'Daily Updates by Salable',
                accentColor: '#EBEDF0'
            })
            return (<div>Loading...</div>)
        }
        mainDialogContent = (
            <Grid item xs={8} md={10} xl={9}>
                <div className="popup__wrapper">
                    <PersonalUpdate
                        date={date}
                        onChangeDate={onChangeDate}
                        onUpdated={onUpdated}
                    />
                    <TeamUpdates
                        date={date}
                        isUpdated={isUpdated}
                    />
                </div>
            </Grid>
        )
    }

    const dailyUpdatesButton = [
        <ButtonItem isSelected={selectedMenuItem === DailyUpdatesMenuEnum.DAILY_UPDATES}
                    iconBefore={<CalendarIcon primaryColor={'#42526E'} label="Export"/>}
                    key={DailyUpdatesMenuEnum.DAILY_UPDATES}
                    onClick={() => {
                        setSelectedMenuItem(DailyUpdatesMenuEnum.DAILY_UPDATES)
                    }}>
            Daily Updates
        </ButtonItem>
    ]

    return (
        <Grid container className={classes.container}>
            {/* @ts-ignore*/}
            <LeftBar selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem}
                     externalButtons={dailyUpdatesButton}/>
            {mainDialogContent}
        </Grid>
    )
}

export default DailyUpdates

import {
    GetUpdateProperties,
    GetUpdatesProperties,
    SaveUpdateProperties,
    UpdateProperties
} from './PersistenceProperties'
import TrelloDetails from '../../utils/TrelloDetails'
import Member from 'trello-shared-resources/dist/types/Member'
import {getToken} from 'trello-shared-resources/dist/services/TokenService'
import TrelloUrlBuilder from 'trello-shared-resources/dist/modules/url/TrelloUrlBuilder'
import TrelloEntityType from 'trello-shared-resources/dist/modules/url/TrelloEntityType'

class Persistence {
    private static BASE_URL = process.env.REACT_APP_API_GATEWAY_URL
    private static GET_UPDATE_ENDPOINT = '/get-update'
    private static SAVE_UPDATE_ENDPOINT = '/save-update'
    private static GET_UPDATES_ENDPOINT = '/get-all-updates'

    private static getUpateURL = Persistence.BASE_URL + Persistence.GET_UPDATE_ENDPOINT
    private static saveUpdateURL = Persistence.BASE_URL + Persistence.SAVE_UPDATE_ENDPOINT
    private static getUpdatesURL = Persistence.BASE_URL + Persistence.GET_UPDATES_ENDPOINT

    private static members: Array<Member> = []

    static getUpdate = async (getUpdatePropterties: GetUpdateProperties) => {
        const postData = await Persistence.appendToken(getUpdatePropterties)
        return fetch(Persistence.getUpateURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.ok) return response.json()
                else return false
            })
            .catch(error => {
                return false
            })
    }

    static saveUpdate = async (saveUpdateProperties: SaveUpdateProperties) => {
        const postData = await Persistence.appendToken(saveUpdateProperties)
        return fetch(Persistence.saveUpdateURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
    }

    static getUpdates = async (getUpdatesProperties: GetUpdatesProperties) => {
        if (Persistence.members.length === 0) await Persistence.storeBoardMembers()
        const postData = await Persistence.appendToken(getUpdatesProperties)
        return fetch(Persistence.getUpdatesURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.ok) return response.json()
                else return []
            })
            .then(async (updates: Array<SaveUpdateProperties>) => {
                const filledUpdates = []
                for(const update of updates) {
                    filledUpdates.push(await Persistence.fillUserData(update))
                }
                return filledUpdates
            })
            .catch(error => {
                console.error('There was an error getting updates', error)
                return []
            })
    }

    static appendToken = async (postData: GetUpdateProperties | GetUpdatesProperties | SaveUpdateProperties) => {
        return {
            ...postData,
            token: await TrelloDetails.getJwt()
        }
    }

    /**
     * Fill user data (username and fullName) for the given update with member_id
     * @param update object that contains the member_id attr
     * @return an UpdateProperties object with user data
     */
    static fillUserData = async (update: SaveUpdateProperties): Promise<UpdateProperties> => {
        const filledUpdate = update as UpdateProperties
        let member = Persistence.members.find(member => member.id === filledUpdate.member_id)
        if (!member) member = await Persistence.findMissingMember(filledUpdate.member_id)

        filledUpdate.username = member?.username || ''
        filledUpdate.fullName = member?.fullName || ''
        return filledUpdate
    }

    /**
     * Find member on the trello API and store it on members array
     * @param memberId the member identifier
     * @return a promise that will return the member found
     */
    static findMissingMember = async (memberId: string): Promise<Member | undefined> => {
        const member = await Persistence.getMemberById(memberId)
        if (member) Persistence.members.push(member)
        return member
    }
    /**
     * Store board members in this class
     */
    private static storeBoardMembers = async () => {
        const board = await TrelloDetails.getContext().board('all')
        Persistence.members = board.members
    }

    /**
     * Makes a get request to the given URL
     * @param url address to make the request
     * @return the JSON if the response goes well or undefined otherwise
     */
    static get = async (url: string) => {
        const response = await fetch(url, {method: 'GET'})
        if (response.ok) return await response.json()
        else return undefined
    }

    /**
     * Find member by the given Id
     * @param licenseDetails
     * @param memberId
     * @return a Promise<Member>
     */
    static getMemberById = async (memberId: string): Promise<Member | undefined> => {
        const licenseDetails = TrelloDetails.getLicenseDetails()
        if (!licenseDetails || !memberId) {
            return Promise.resolve(undefined)
        }
        const key = licenseDetails.apiKey
        const token = await getToken()

        const getMemberURL = new TrelloUrlBuilder()
            // Authentication
            .withKey(key)
            .withToken(token)

            // Entity Type Request
            .withEntityType(TrelloEntityType.Members)
            .withEntityId(memberId)
            .build()

        return Persistence.get(getMemberURL)
    }
}

export default Persistence

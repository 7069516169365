import React, {useEffect, useState} from 'react'
import UserUpdatesProperties from '../../types/UserUpdatesProperties'
import {UpdateProperties} from '../../modules/persistence/PersistenceProperties'
import moment from 'moment'
import DailyUpdatesDate from '../../utils/DailyUpdatesDate'
import UserUpdate from './UserUpdate'
import {Alert} from '@material-ui/lab'
import Persistence from '../../modules/persistence/Peristence'
import TrelloDetails from '../../utils/TrelloDetails'

const UserUpdates = (props: UserUpdatesProperties) => {

    const defaultUpdates = {
        board_id: '',
        member_id: '',
        yesterday: '',
        today: '',
        issues: '',
        date: '',
        fullName: '',
        username: '',
        id: ''
    }
    const [updates, setUpdates] = useState<UpdateProperties[]>([defaultUpdates])
    const [error, setError] = useState(false)

    useEffect(() => {
        const getUpdatesAsync = async () => {
            try {
                setUpdates(await Persistence.getUpdates({
                    date: props.datePickerDate,
                    board_id: TrelloDetails.getBoardId(),
                    member_id: TrelloDetails.getMemberId()
                }))
            } catch (ex) {
                console.error('Daily Updates fetch error: ' + ex)
                setError(true)
            }
        }
        getUpdatesAsync()
    }, [props.datePickerDate, props.isUpdated])

    if (error) {
        return <Alert severity="error">There was an error retrieving Daily Updates</Alert>
    }

    if (updates.length < 1 || updates[0]?.board_id === '') {
        return <div>There are no updates for {moment(props.datePickerDate).format(DailyUpdatesDate.DISPLAY_DATE_FORMAT)} yet.</div>
    }
    return (
        <React.Fragment>
            {updates.map((update, idx) =>
                <UserUpdate
                    key={idx}
                    fullName={update.fullName}
                    userName={update.username}
                    yesterdayComment={update.yesterday}
                    todayComment={update.today}
                    issuesComment={update.issues}
                />)
            }
        </React.Fragment>
    )
}

export default UserUpdates
import {useEffect} from "react"
import {initializeCapabilities, PageView, TrackActionEvent,} from "trello-shared-resources"
import {showDarkThemeNotSupportedMessage} from "trello-shared-resources/dist/services/DarkThemeService"

/* 
    The Connector is used to tell Trello what capabilities your power-up should have. 
    This Connector should be used to add new menu items, buttons and other similar capabilities.
*/
const Connector = () => {
  const capabilities = {
    "board-buttons": function (t: any, options: any) {
      return [
        {
          text: "Daily Updates",
          icon: "./icons/du-logo-white.svg",
          // Board buttons need their own modal function
          callback: function (trelloContext: any, opts: any) {
            const context = trelloContext.getContext()
            TrackActionEvent('Button Click', context, {
              board_id: context.board,
              button: 'Open Daily_Updates'
            })

            showDarkThemeNotSupportedMessage(trelloContext)
            return trelloContext.modal({
              url: trelloContext.signUrl("./index.html?apptype=daily-updates"),
              accentColor: "#EBEDF0",
              fullscreen: true,
              resizable: false,
              title: "Daily Updates by Salable",
              callback: function (t: any, opts: any) {
                const context = t.getContext()
                TrackActionEvent('Button Click', context, {
                  board_id: context.board,
                  button: 'Close Daily_Updates'
                })
              },
            })
          },
        },
      ]
    },
  }

  useEffect(() => {
    PageView("PowerUp Connector")
  }, [])

  initializeCapabilities(capabilities)

  return null
}

export default Connector

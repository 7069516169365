import React, { useState, useEffect } from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import DatePickerProperties from '../../types/DatePickerProperties'
import DailyUpdatesDate from '../../utils/DailyUpdatesDate'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import TrelloDetails from '../../utils/TrelloDetails'

/*
Latest date to be selected: it corresponds to tomorrow's date.
Dates after tommorrow on are disabled. 
*/
const MAX_DATE = DailyUpdatesDate.getFutureDate(moment(), 1)

const DatePicker = (props: DatePickerProperties) => {
  const {date, setDatePickerDate} = props
  const trelloContext = TrelloDetails.getContext().getContext()

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    new Date(moment(date).format(DailyUpdatesDate.DISPLAY_DATE_FORMAT))
  )

  // This keeps the DatePicker synchronized with the Date component
  useEffect(() => {
    setSelectedDate(new Date(moment(date).format(DailyUpdatesDate.DISPLAY_DATE_FORMAT)))
    setDatePickerDate(moment(date).format(DailyUpdatesDate.PERSISTANCE_DATE_FORMAT).toString())
  }, [date, setDatePickerDate])

  const onChangeDate = (date: Date | null) => {
    setSelectedDate(date)
    setDatePickerDate(moment(date!).format(DailyUpdatesDate.PERSISTANCE_DATE_FORMAT).toString())
    TrackActionEvent('Change Team Update Date', trelloContext, {board_id: trelloContext.board})
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          disableToolbar
          format="dd MMMM yyyy"
          margin="normal"
          id="daily-updates-date-picker"
          value={selectedDate}
          onChange={onChangeDate}
          minDate={DailyUpdatesDate.MIN_DATE}
          maxDate={MAX_DATE}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker

import React from 'react'
import TeamUpdateMenuProperties from '../../types/TeamUpdateMenuProperties'
import DatePicker from '../date-picker/DatePicker'
import Download from '../download/Download'
import Box from '@material-ui/core/Box'

const TeamUpdateMenu = (props: TeamUpdateMenuProperties) => (
  <Box
    display="flex"
    justifyContent="center"
    p={1}
    m={1}
    bgcolor="background.paper"
  >
    <Box p={3} alignItems="center"  justifyContent="center">
      Your team's updates for
    </Box>

    <Box>
      <DatePicker
        date={props.date}
        setDatePickerDate={props.setDatePickerDate}
      />
    </Box>

    <Box>
      <Download date={props.datePickerDate} />
    </Box>
  </Box>
)

export default TeamUpdateMenu

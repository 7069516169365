import React, {useState} from 'react'
import TeamUpdatesProperties from '../../types/TeamUpdatesProperties'
import TeamUpdateMenu from '../team-update-menu/TeamUpdateMenu'
import UserUpdates from '../user-update/UserUpdates'

const TeamUpdates = (props: TeamUpdatesProperties) => {

    const [datePickerDate, setDatePickerDate] = useState(props.date)

    return (
        <React.Fragment>
            <TeamUpdateMenu
                date={props.date}
                datePickerDate={datePickerDate}
                setDatePickerDate={setDatePickerDate}
            />
            <UserUpdates
                datePickerDate={datePickerDate}
                date={props.date}
                isUpdated={props.isUpdated}
            />
        </React.Fragment>
    )
}

export default TeamUpdates
import React, {useContext, useState} from 'react'
import Button from '@material-ui/core/Button'
import {useStyles} from './saveUpdateStyles'
import SaveUpdateProperties from '../../types/SaveUpdateProperties'
import { LicenseDetailsContext, TrackActionEvent } from 'trello-shared-resources/dist'
import {Alert} from '@material-ui/lab'
import {Grid} from '@material-ui/core'

const SaveUpdate = (props: SaveUpdateProperties) => {

    const classes = useStyles()

    const isYesterdayCommentEmpty = props.yesterdayComment.trim().length === 0
    const isTodayCommentEmpty = props.todayComment.trim().length === 0
    const isIssuesCommentEmpty = props.issuesComment.trim().length === 0

    const [message, setMessage] = useState('')
    const [animating, setAnimating] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)
    const licenseDetailsContext = useContext(LicenseDetailsContext)

    const onSaveUpdate = () => {
        setIsEmpty(false)
        if (
            isYesterdayCommentEmpty &&
            isTodayCommentEmpty &&
            isIssuesCommentEmpty
        ) {
            setMessage('Type your updates above to save ☝️')
            setIsEmpty(true)
        } else {
            setMessage('')
            setAnimating(true)
            props.onSaveUpdate()
                .then(response => setMessage(response))
                .catch(response => setMessage(response))
            const filledFields  = ['yesterday','today','issues'].filter(value =>
                ('yesterday' === value && !isYesterdayCommentEmpty) ||
                ('today' === value && !isTodayCommentEmpty) ||
                ('issues' === value && !isIssuesCommentEmpty)
            )
            console.error(JSON.stringify(licenseDetailsContext));
            const context = licenseDetailsContext.trelloIframeContext.getContext()
            TrackActionEvent('Button Click Save', context, {board_id: context.board, filled_fields: filledFields})
        }
    }

  const readonlyMessage = process.env.REACT_APP_READONLY_MODE === 'true' ? <Grid container justify="center" alignContent="center">
    <Grid item xs={10} >
      <Alert severity="warning">Apologies, we are on maintenance. Try to reload the page and try again.</Alert>
    </Grid>
  </Grid> : <></>

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        onClick={onSaveUpdate}
        disabled={process.env.REACT_APP_READONLY_MODE === 'true'}
      >
        Save daily update
      </Button>
        {readonlyMessage}

        <span
        onAnimationEnd={() => setAnimating(false)}
        id="daily-updates-save-update-message"
        className={animating ? classes.animatedAlert : ''}
        style={{
          position: "absolute",
          marginTop: "12px",
          opacity: isEmpty ? 1 : 0
        }}>
        {message}
      </span>
        </React.Fragment>
    )
}

export default SaveUpdate

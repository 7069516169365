import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      textTransform: 'capitalize'
    },
    "@keyframes flickerAnimation": {
      "0%": {
        opacity: 1
      },
      "50%": {
        opacity: 0.9
      },
      "100%": {
        opacity: 0
      }
    },
    animatedAlert: {
        animation: `$flickerAnimation 2000ms linear`
    },
  }),
)

export { useStyles }

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
        paddingTop: 10,
      },
    },
    inputLabel: {
      fontSize: 16,
      fontWeight: "bold",
    },
  })
);

export { useStyles }
